<template>
    <div class="sidebarPart openright">
        <ul>
            <!-- <li class="hotlineService" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                <img :src="phoneImg" alt="">
                <div class="intro" v-if="phoneFlag">
                    <div class="contact">联系电话</div>
                    <div>+86 177 8608 3608</div>
                    <img src="../../assets/image/arrow_home.png" alt="">
                </div>
            </li>
            <li class="onlineConsultation" @mouseenter="handleMouseEnterCode" @mouseleave="handleMouseLeaveCode">
                <img :src="codeImg" alt="">
                <div class="introCode" v-if="codeFlag">
                    <img class="code" src="../../assets/image/companyContact.jpg" alt="">
                    <img class="arrow" src="../../assets/image/arrow_home.png" alt="">
                </div>
            </li> -->
            <li>
                <div class="siderText" @click="toOrder">按需<br>订阅</div>
            </li>
            <li>
                <div class="divide"></div>
            </li>
            <li>
                <div class="siderText" @click="toFree">免费<br>软件</div>
            </li>
            <li>
                <div class="divide"></div>
            </li>
            <li @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
                <div class="siderText">联系<br>客服</div>
                <div class="intro" v-if="phoneFlag">
                    <!-- <div class="contact">联系电话</div>
                    <div>+86 177 8608 3608</div>
                    <img src="../../assets/image/arrow_home.png" alt=""> -->
                    <div class="introItem">
                        <div class="introIcon">
                            <img :src="phoneImg" alt="">
                        </div>
                        <div class="introContent">
                            <div class="introT">电话客服</div>
                            <div class="introTime">工作日 9:00-18:00</div>
                            <div class="introPhone">+86 177 8608 3608</div>
                        </div>
                    </div>
                    <div class="introItem">
                        <div class="introIcon">
                            <img :src="codeImg" alt="">
                        </div>
                        <div class="introContent">
                            <div class="introT">企业微信</div>
                            <div class="introImg">
                                <img class="companyWechet" src="../../assets/image/companyContact.jpg" />
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="divide"></div>
            </li>
            <li>
                <div @click="toTop" class="introIcon">
                    <img :src="backImg" alt="">
                </div>
            </li>
        </ul>

    </div>
</template>

<script>
export default {
    props: ["paneFlag"],
    data() {
        return {
            phoneImg: require('../../assets/image/home_phoneicon.png'),
            codeImg: require('../../assets/image/home_chaticon.png'),
            backImg: require('../../assets/image/home_upicon.png'),
            phoneFlag: false,
            codeFlag: false
        };
    },
    mounted() {

    },
    watch: {
        paneFlag() {
            // const content = document.querySelector('.sidebarPart');
            // if (this.paneFlag) {
            //     content.classList.remove('hiddenright');
            //     content.classList.add('openright');
            // } else {
            //     content.classList.remove('openright');
            //     content.classList.add('hiddenright');
            // }
        }
    },
    methods: {
        handleMouseEnter() {
            // 当鼠标进入按钮时触发该函数
            // this.phoneImg = require('../../assets/image/home_phone_select.png');
            this.phoneFlag = true;
        },
        handleMouseLeave() {
            // 当鼠标离开按钮时触发该函数
            // this.phoneImg = require('../../assets/image/home_phone.png');
            this.phoneFlag = false;
        },
        handleMouseEnterCode() {
            // 当鼠标进入按钮时触发该函数
            this.codeImg = require('../../assets/image/home_code_select.png');
            this.codeFlag = true;
        },
        handleMouseLeaveCode() {
            // 当鼠标离开按钮时触发该函数
            this.codeImg = require('../../assets/image/home_code.png');
            this.codeFlag = false;
        },
        toOrder() {
            this.$router.push({
                path: "/Buy",
            });
        },
        toFree() {
            this.$router.push({
                path: "/FreeSoft",
            });
        },
        toTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
};
</script>

<style scoped lang="scss" scoped>
.sidebarPart {
    width: 50px;
    height: 90px;
    position: fixed;
    top: 0;
    bottom: 0%;
    margin: auto 0;
    // right: 40px;
    right: 0px;
    z-index: 3;
    transform: translateX(62px);
    // background-color: pink;

    ul {
        font-size: 14px;
        width: 50px;
        opacity: 1;
        border-radius: 4px;
        background: rgba(255, 255, 255, 1);

        border: 1px solid rgba(230, 230, 230, 1);
        padding: 15px 7px;
        margin-right: 5px;
        box-sizing: border-box;



        // li {
        //     width: 36px;
        //     height: 36px;
        //     opacity: 1;
        //     border-radius: 20px;
        //     background: rgba(255, 255, 255, 1);
        //     box-shadow: 0px 0px 6px rgba(128, 128, 128, 0.2);
        //     box-sizing: border-box;
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     cursor: pointer;
        //     margin-bottom: 13px;
        //     position: relative;
        // }
        li {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 18px;
            color: rgba(102, 102, 102, 1);
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
        }

        .divide {
            height: 1px;
            opacity: 1;
            background: rgba(245, 245, 245, 1);
            margin: 15px 0;
        }

        .siderText:hover {
            color: rgba(43, 133, 229, 1);
        }

        .hotlineService {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 15px;
                height: 14px;
            }
        }

        .onlineConsultation {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 17px;
                height: 16px;
            }
        }

        .intro {
            width: 173px;
            height: 263px;
            opacity: 1;
            border-radius: 4px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            text-align: left;
            vertical-align: top;
            position: absolute;
            right: 60px;
            top: 120px;
            display: flex;
            flex-direction: column;
            padding: 18px;
            padding-right: 0;

            .contact {
                border-bottom: 0.5px solid rgba(229, 229, 229, 1);
            }

            .introItem {
                display: flex;
                padding-bottom: 10px;

                .introIcon {
                    width: 18px;
                    height: 18px;
                    margin-right: 7px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .introContent {
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 0px;
                    line-height: 18px;
                    color: rgba(43, 133, 229, 1);
                    text-align: left;
                    vertical-align: middle;

                    .introTime {
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 18px;
                        color: rgba(56, 56, 56, 1);
                        text-align: left;
                        vertical-align: middle;
                        margin-top: 10px;
                    }

                    .introPhone {
                        opacity: 1;
                        border-radius: 4px;
                        background: rgba(43, 133, 229, 1);
                        margin-top: 10px;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 18px;
                        color: rgba(255, 255, 255, 1);
                        text-align: center;
                        vertical-align: middle;
                        padding: 5px 3px;
                        margin-right: 5px;
                    }

                    .introImg {
                        width: 125px;
                        height: 119px;

                        img {
                            width: 100%;
                        }
                    }
                }
            }

            // img {
            //     width: 12px;
            //     height: 12px;
            //     position: absolute;
            //     top: 0;
            //     bottom: 0;
            //     margin: auto 0;
            //     right: -6px;
            // }
        }

        .introCode {
            width: 132px;
            height: 116px;
            opacity: 1;
            border-radius: 5px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 60px;
            top: -40px;

            .code {
                width: 89px;
                height: 88px;
            }

            .arrow {
                width: 12px;
                height: 12px;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto 0;
                right: -6px;
            }
        }
    }
}

.openright {
    animation: slideInRight .36s forwards;
}

.hiddenright {
    animation: slideOutLeft .36s forwards;
}

@keyframes slideInRight {
    0% {
        transform: translateX(82px);
        // opacity: 0;
    }

    100% {
        transform: translateX(0);
        // opacity: 1;
    }
}

@keyframes slideOutLeft {
    0% {
        transform: translateX(0);
        // opacity: 1;
    }

    100% {
        transform: translateX(82px);
        // opacity: 0;
    }
}
</style>