var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebarPart openright"},[_c('ul',[_c('li',[_c('div',{staticClass:"siderText",on:{"click":_vm.toOrder}},[_vm._v("按需"),_c('br'),_vm._v("订阅")])]),_vm._m(0),_c('li',[_c('div',{staticClass:"siderText",on:{"click":_vm.toFree}},[_vm._v("免费"),_c('br'),_vm._v("软件")])]),_vm._m(1),_c('li',{on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[_vm._m(2),(_vm.phoneFlag)?_c('div',{staticClass:"intro"},[_c('div',{staticClass:"introItem"},[_c('div',{staticClass:"introIcon"},[_c('img',{attrs:{"src":_vm.phoneImg,"alt":""}})]),_vm._m(3)]),_c('div',{staticClass:"introItem"},[_c('div',{staticClass:"introIcon"},[_c('img',{attrs:{"src":_vm.codeImg,"alt":""}})]),_vm._m(4)])]):_vm._e()]),_vm._m(5),_c('li',[_c('div',{staticClass:"introIcon",on:{"click":_vm.toTop}},[_c('img',{attrs:{"src":_vm.backImg,"alt":""}})])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"divide"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"divide"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"siderText"},[_vm._v("联系"),_c('br'),_vm._v("客服")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introContent"},[_c('div',{staticClass:"introT"},[_vm._v("电话客服")]),_c('div',{staticClass:"introTime"},[_vm._v("工作日 9:00-18:00")]),_c('div',{staticClass:"introPhone"},[_vm._v("+86 177 8608 3608")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"introContent"},[_c('div',{staticClass:"introT"},[_vm._v("企业微信")]),_c('div',{staticClass:"introImg"},[_c('img',{staticClass:"companyWechet",attrs:{"src":require("../../assets/image/companyContact.jpg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"divide"})])
}]

export { render, staticRenderFns }